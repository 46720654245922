.label {
    font-size: 12px;
    font-weight: 700;
}

.historyGraphsContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.historyRow {
    display: flex;
    margin-bottom: 3px;
    justify-content: center;
    align-items: center;
}

.historyBar {
    margin-left: 10px;
    flex-grow: 0;
}

.historyActiveBar,
.historyTotalBar,
.historyMissingBar {
    height: 12px;
}

.historyActiveBar {
    background-color: grey;
}

.historyTotalBar {
    background-color: lightgrey;
}

.historyBar {
    display: flex;
    width: 100%;
}

.historyMissingBar {
    flex-grow: 1000;
}

.historyActiveBar {
    flex-grow: 0;
    color: white;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    transition: width 1s;
    transition-timing-function: ease-out;
}

.historyTotalBar {
    flex-grow: 0;
    transition: width 1s;
    transition-timing-function: ease-out;
}

.historyRow:hover .historyActiveBar,
.historyActiveBar:hover {
    background-color: blue;
}

.historyRow:hover .historyTotalBar,
.historyTotalBar:hover {
    background-color: lightblue;
}

.historyCaption {
    filter: contrast(0);
}

.historyRow:hover .historyCaption,
.historyCaption:hover {
    filter: none;
}

.historyTooltip {
    margin-top: 0px;
}