html {
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

sup {
    font-size: medium;
}

#root {
    height: 100vh;
    height: -webkit-fill-available;
}

.drawer {
    min-height: 100vh;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.toggles {
    padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
    .toggles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;
    }
}

@media screen and (max-width: 600px) {
    .toggles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .toggles>div {
        margin-bottom: 10px;
    }
}

@keyframes myRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadingLogo {
    animation-name: myRotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: 200px;
    margin-bottom: 30px;
    align-self: center;
}

.loadingDiv {
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 30px;
    flex-direction: column;
}

.yearSelect.Mui-selected {
    font-weight: 700;
}

.yearSelect:nth-child(6n+1).Mui-selected {
    background-color: #f44336;
    color: rgb(255, 255, 255);
}

.yearSelect:nth-child(6n+1).Mui-selected:hover {
    background-color: #e53935;
    color: rgb(255, 255, 255);
}

.yearSelect:nth-child(6n+2).Mui-selected {
    color: rgb(255, 255, 255);
    background-color: #ff9800;
}

.yearSelect:nth-child(6n+2).Mui-selected:hover {
    background-color: #fb8c00;
    color: rgb(255, 255, 255);
}

.yearSelect:nth-child(6n+3).Mui-selected {
    color: rgb(255, 255, 255);
    background-color: #ffc107;
}

.yearSelect:nth-child(6n+3).Mui-selected:hover {
    background-color: #ffb300;
    color: rgb(255, 255, 255);
}

.yearSelect:nth-child(6n+4).Mui-selected {
    color: rgb(255, 255, 255);
    background-color: #4caf50;
}

.yearSelect:nth-child(6n+4).Mui-selected:hover {
    background-color: #43a047;
    color: rgb(255, 255, 255);
}

.yearSelect:nth-child(6n+5).Mui-selected {
    color: rgb(255, 255, 255);
    background-color: #2196f3;
}

.yearSelect:nth-child(6n+5).Mui-selected:hover {
    background-color: #1e88e5;
    color: rgb(255, 255, 255);
    ;
}

.yearSelect:nth-child(6n+6).Mui-selected {
    color: rgb(255, 255, 255);
    background-color: #9c27b0;
}

.yearSelect:nth-child(6n+6) {
    background-color: #8e24aa;
    color: rgb(255, 255, 255);
}

.visibleGroup {
    display: inherit
}

.hiddenGroup {
    display: none !important
}

.chart {
    position: relative;
    height: 60vh !important;
    width: 100% !important;
}

#myGraph {
    width: 100% !important;
}