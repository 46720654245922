.infoPanelBottom {
    display: flex;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 24px;
    justify-content: space-between;
}

.infoPanelInfoIcon,
.infoPanelChartIcon {
    align-self: right;
    text-align: right;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 25px;
    filter: contrast(0) opacity(0.7);
}

.infoPanelInfoIcon:hover,
.infoPanelChartIcon:hover {
    filter: none;
}